const initState = {
  currencySymbol: "₹",  
  currencyName: "INR",   
  currencyRate: 1       
};

const currencyReducer = (state = initState, action) => {
  switch (action.type) {
    case "CHANGE_CURRENCY":
      return {
        ...state,
        currencyName: action.payload.currencyName,
        currencySymbol: action.payload.currencySymbol,
        currencyRate: action.payload.currencyRate,
      };
    default:
      return state;
  }
};

export default currencyReducer;
