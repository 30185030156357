export const changeCurrency = (currencyName, currencySymbol, currencyRate) => {
  return {
    type: "CHANGE_CURRENCY",
    payload: {
      currencyName,
      currencySymbol,
      currencyRate
    }
  };
};
